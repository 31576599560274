/* eslint-disable jsx-a11y/no-distracting-elements */

import React from 'react';
import { Link } from 'react-router-dom';

function MainPage() {
  console.log("MainPage component rendered");

  return (
    <div className="home">
      <h1>Welcome to HabboUp</h1>
      <p>
        Welcome to HabboUp, where my passion for Habbo mazes comes to life! I've spent countless hours navigating these intricate mazes, sharing laughs and unforgettable moments with friends. Join me in celebrating the joy and nostalgia of Habbo Hotel Origins.
      </p>
      <section>
        <h2>Explore</h2>
        <ul>
          <li><Link to="/screenshots">Screenshots</Link></li>
          <li><Link to="/analytics">Analytics</Link></li>
          <li><Link to="/mazehub">Maze HUB</Link></li>
        </ul>
      </section>
      <div className="marquee">
        <marquee>
          <img src="/boahsWaving.gif" alt="Habbo Waving" style={{ verticalAlign: 'middle', marginRight: '10px' }} />
          Hi! I'm Boahs - Welcome to my origins fansite!
          <img src="/boahsDancing.gif" alt="Habbo Dancing" style={{ verticalAlign: 'middle', marginRight: '10px' }} />
          <img src="/boahsDancing2.gif" alt="Habbo Dancing" style={{ verticalAlign: 'middle', marginRight: '10px' }} />
          📰Latest News!📰 
          <img src="/boahsDancing2.gif" alt="Habbo Dancing" style={{ verticalAlign: 'middle', marginRight: '10px' }} />
          💾Check out our new maze HUB page!💾💾,💾BattleBall is coming soon! Big prize pool features Gold, Silver and Bronze dragons!💾
        </marquee>
      </div>
    </div>
  );
}

export default MainPage;
