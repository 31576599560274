import React from 'react';

const MazeCard = ({ maze }) => {
  return (
    <div className="maze-card">
      <img src={maze.imageUrl} alt={maze.name} className="profile-img" />
      <h2>{maze.name}</h2>
      <p>Creator: {maze.creator}</p>
      <p>Difficulty: {maze.difficulty}</p>
      <p>Type: {maze.type.join(', ')}</p>
      <p>Themed Rooms: {maze.themedRooms ? 'Yes' : 'No'}</p>
      <p>Floors Currently: {maze.floorsCurrently}</p>
      <p>Floors Planned: {maze.floorsPlanned}</p>
      <div className="tags">
        <span className={`tag difficulty-${maze.difficulty.toLowerCase()}`}>
          {maze.difficulty}
        </span>
        {maze.type.map((type, index) => (
          <span key={index} className="tag">{type}</span>
        ))}
      </div>
      <div className="social-media-links">
        {maze.twitter && (
          <a href={maze.twitter} target="_blank" rel="noopener noreferrer">
            <img src={process.env.PUBLIC_URL + '/twitterLogo.png'} alt="Twitter" className="social-icon" />
          </a>
        )}
        {maze.twitch && (
          <a href={maze.twitch} target="_blank" rel="noopener noreferrer">
            <img src={process.env.PUBLIC_URL + '/twitchLogo.png'} alt="Twitch" className="social-icon" />
          </a>
        )}
        {maze.youtube && (
          <a href={maze.youtube} target="_blank" rel="noopener noreferrer">
            <img src={process.env.PUBLIC_URL + '/youtubeLogo.png'} alt="YouTube" className="social-icon" />
          </a>
        )}
      </div>
    </div>
  );
};

export default MazeCard;
