import React, { useState } from 'react';

function ScreenshotsPage() {
  const screenshots = [
    {
        src: "/habboUpDoorMatsFunkyFriday.png",
        alt: "Yes I spent around $80-90 on funky friday doormats to make this. No regrets. Maybe some regrets. Ok, a few regrets.",
        description: "Behold! The Funky Friday(7/19/24) Doormat Creation! I'm attemping to spell habboUP.com if you can't tell. I did my best, give me a break :D.",
        roomOwner: "Boahs"
      },
    {
      src: "/theTerrorSpotPrepatch.png",
      alt: "terror spot...OMG!",
      description: "When Habbo Hotel Origins launched some items were missing sprites and instead replaced with a PH box icon for a placeholder. The pura bed was one of these items (which is what you see in the screenshot above) - this specific interaction gave a lot of habbos issues clicking to move on in this floor. I called this spot... Terror Spot of The Greenery Gauntlet",
      roomOwner: "Boahs"
    },
    {
      src: "/greeneryGauntletLevel.png",
      alt: "greenery gauntlet II",
      description: "This was originally the first floor in my own maze. After many complaints of how difficult it was I would then move it from floor 1 to floor 2 to floor 4 to floor 6 and finally floor 7.",
      roomOwner: "Boahs"
    },
    {
      src: "/userStuckInMazeFloor7.png",
      alt: "A poor soul wasting their time in the boahs maze...Oh that's mine!",
      description: "A player finding himself stuck in one of four Greenery Gauntlet floors!",
      roomOwner: "Boahs"
    },
    {
      src: "/decrepitatedFloor.png",
      alt: "We will forever miss you, missing level.",
      description: "A deprecated floor of the four Greenery Gauntlet floors - This was when 'kill spots' were in my maze. I soon realized how unfun these kinds of spots were and opted to redesign the floors that had those types of spots. Now this floor is an ancient relic forever lost to the Greenery Gauntlets vast jungles.",
      roomOwner: "Boahs"
    },
    {
      src: "/decrepitatedFloor2.png",
      alt: "Friggin' Sulake and their lack of QA",
      description: "Yet another deprecated floor. The library of relics floor 2 was the only featured large room in my maze and was created sometime around 6/24/2024. This level soon would be removed entirely from the maze due to the fact that it would crash users randomly and quite often. Players would teleport to the level and immediately black screen. I was never sure what caused this and decided to trash it as a whole. Another floor forever lost.",
      roomOwner: "Boahs"
    },
    {
      src: "/threePeopleStuckInGauntlet.png",
      alt: "Our good friends stuck in the gauntlet",
      description: "Three people stuck inside the Gauntlet at the same time! This was actually the first room I built if you notice the room name - [1] Boahs Maze the numbers refer to the floor / level. Many complaints later we would move this level up due to the difficulty.",
      roomOwner: "Boahs"
    },
    {
      src: "/chattingInWinnerRoom.png",
      alt: "Pedguin I love you and am sry. I never thought your maze was easy by a long shot.",
      description: "Chatting with some good friends inside the 'winners area' of the maze lobby!",
      roomOwner: "Boahs"
    },
    {
      src: "/earlyWinnerSticky.png",
      alt: "The earlier beta testers of the maze. Also two winners relaxing after beating the maze.",
      description: "A earlier screenshot of the sticky where I would attempt to log everyname that completed the maze as it was being built.",
      roomOwner: "Boahs"
    },
    {
      src: "/catTalkingShit.png",
      alt: "Otis would think my maze is shitty...",
      description: "My in game pet talking bad about my maze...come on.",
      roomOwner: "Boahs"
    },
    {
      src: "/vincentOwningMyMaze.png",
      alt: "Vincent...our good friend from New Zealand!",
      description: "One of our most awesome community members streaming his attempt of my maze. Vincent absolutely crushed it too - I'm almost positive he completed every floor within 35 or 40 minutes on his first go.",
      roomOwner: "Boahs"
    },
    {
      src: "/charliesFunkyMazeDone.png",
      alt: "Completing charlies funky maze!",
      description: "Celebrating with comrades! Vincent, Oily, Robbie, and I had just completed Charlie's maze. What a maze too - his rollar/teleport floor is an absolute masterpiece.",
      roomOwner: "Charlie"
    },
    {
      src: "/al3naHangingOut.png",
      alt: "al3na you should of said cheese!",
      description: "Hanging out with Al3na after she had completed the maze. She did not say Cheese before I hit print screen.",
      roomOwner: "Boahs"
    },
    {
      src: "/cursedMatMaze.png",
      alt: "I always refer to this damn level as my Frankenstein floor. Mary Shelley - Eat your heart out.",
      description: "This floor is just a complete pain in the ass. I know it. People that play my mazes know it. The president of the united states knows it. Even Macklebee probably knows it - but I've always called this floor my personal frankenstein. I've created a monster and understand that - I don't have the heart to trash it. A lot of people hate this level and for good reason! Pay attention to the rollars :).",
      roomOwner: "Boahs"
    },
    {
      src: "/moreStickyNames.png",
      alt: "More souls corrupted",
      description: "Some more early names on the winners sticky.",
      roomOwner: "Boahs"
    },
    {
      src: "/coolScriptedRoom.png",
      alt: "Don't ban me, Sulake",
      description: "Just a really cool room covered in garlands.",
      roomOwner: "Boahs"
    },
    {
      src: "/wowinStaffRoom.png",
      alt: "Yeah that 'Wowin' the staff member",
      description: "Partying in the staff memeber Wowin 'Poolside Cafe & Trophies' room with loads of other people. Friends and strangers saying CHEESE for the screenshot!",
      roomOwner: "Wowin"
    },
    {
      src: "/CensoredfallingFurniGroupPic.png",
      alt: "I gave my spot up for jij to win a dino egg in the previous round.",
      description: "Some friends and strangers all playing fallin' furni - Was here for multiple games and Psy was a great host!",
      roomOwner: "psytechnic"
    },
    {
      src: "/vincentsMaze1.png",
      alt: "Little did I know I was entering one of the seven layers of hell",
      description: "I enter Vincents Maze Manor on its grand opening and venturing forth with me is our friend Al3na. This specific room is very interesting. Check out his maze yourself if you want to know why!",
      roomOwner: "Vincent"
    },
    {
      src: "/vincentsMaze2.png",
      alt: "I was stuck in a dead end for over 15 minutes probably. I never said I was smart.",
      description: "We made it into the next room together - Still just the two of us for now.",
      roomOwner: "Vincent"
    },
    {
      src: "/vincentsMaze3.png",
      alt: "Every habbo maze developer should always add lore VIA sticky notes. Vincent has class. I also have class because I do this too. I mean, I add alt text to images still, so what do you expect?",
      description: "Vincent is awesome for adding lore on the sticky notes. I actually really appreciate it and believe it adds a lot of fun to the maze. Use your imagination, people!",
      roomOwner: "Vincent"
    },
    {
      src: "/vincentsMaze4.png",
      alt: "Goudvis always shows up - Always expect Goudvis",
      description: "As Al3na and I are progressing through this difficult maze Goudvis shows up out of nowhere! This level specifically has two parts within it which is quite interesting. Multiple floors were like this - Great job, Vincent!",
      roomOwner: "Vincent"
    },
    {
      src: "/vincentsMaze5.png",
      alt: "Seriously fuck this room, LOL. This drove me insane. GJ Vincent, you evil genius. This one MIGHT take the cake from even PzziHDDO!",
      description: "Ok. I have a lot of words for this floor but I'll keep it short. It was an absolutely brutal floor and has a lot of tricks. To give some context - Al3na, Goudvis and I were progressing though these floors and after being stuck in this floor for over 50 minutes we had accumulated a train of people waiting behind. Luckily they could watch the people in front! Jokes. Beating this floor was a collective effort and we unironically worked together very well!",
      roomOwner: "Vincent"
    },
    {
      src: "/vincentsMaze6.png",
      alt: "SAY CHEEEESE MY FRIENDS <3! GOUDVIS HAD QUIT BECAUSE WE WERE TAKING TOO LONG OR HE WOULD OF MADE IT HERE TOO!",
      description: "A group picture of some of the people who finished. Had a load of fun in this and I'm so happy I could share it with all of you. Thank you for creating this wonderful maze, Vincent. Thank you to all of my friends for joining me in completing it. Now we party!!!!",
      roomOwner: "Vincent"
    },
    {
        src: "/marioLuigiAutistenRoom.png",
        alt: "Pixel art done with Funky Friday doormats in your hotel? It may be more likely than you think",
        description: "Awesome room and Funky Friday doormat art done by Autisten",
        roomOwner: "Autisten"
    },
    {
      src: "/battleBallArrives.png",
      alt: "Time to get sweaty, nerd.",
      description: "On 8/15/24 Battle ball finally arrived on Habbo Origins!",
      roomOwner: "Public"
    },
  {
    src: "/boahsBattleBallMVP.png",
    alt: "Season 4 league of legends challenger Boahs does it again",
    description: "My first time getting MvP in a public battle ball game. Some powerups are overpowered on day 1!",
    roomOwner: "Public"
    },
  ];

 
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredScreenshots = screenshots.filter((screenshot) =>
    screenshot.roomOwner.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="screenshots">
      <h1>Screenshots</h1>
      <input
        type="text"
        placeholder="Search by Room Owner..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <div className="screenshots-grid">
        {filteredScreenshots.map((screenshot, index) => (
          <ScreenshotItem key={index} screenshot={screenshot} />
        ))}
      </div>
    </div>
  );
}

const readMoreThreshold = 200;

function ScreenshotItem({ screenshot }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="screenshot-item">
      <div className="screenshot-thumbnail-container">
        <a href={screenshot.src} target="_blank" rel="noopener noreferrer">
          <img src={screenshot.src} alt={screenshot.alt} className="screenshot-thumbnail" />
        </a>
      </div>
      <div className={`screenshot-description ${isExpanded ? 'expanded' : ''}`}>
        {isExpanded ? screenshot.description : `${screenshot.description.substring(0, readMoreThreshold)}${screenshot.description.length > readMoreThreshold ? '...' : ''}`}
      </div>
      {screenshot.description.length > readMoreThreshold && (
        <div className="read-more" onClick={toggleReadMore}>
          {isExpanded ? 'Read less' : 'Read more'}
        </div>
      )}
    </div>
  );
}

export default ScreenshotsPage;
