import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import MainPage from './pages/MainPage';
import AnalyticsPage from './pages/AnalyticsPage';
import ScreenshotsPage from './pages/ScreenshotsPage';
import Navigation from './components/Navigation';
import OurFriends from './components/OurFriends'
import MazeHub from './components/MazeHub'

function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />
        <header className="App-header">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/analytics" element={<AnalyticsPage />} />
            <Route path="/screenshots" element={<ScreenshotsPage />} />
            <Route path="/mazehub" element={<MazeHub/>} />
          </Routes>
        </header>
        <OurFriends />
      </div>
    </Router>
  );
}

export default App;
