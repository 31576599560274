import React from 'react';
import '../App.css'; 

const OurFriends = () => {
  return (
    <div className="our-friends">
      <h2>Our Friends</h2>
      <hr />
      <ul>
        <li>
          <a href="https://discord.gg/3A5tza64Zu" target="_blank" rel="noopener noreferrer">
            <img src="/discordLogo.svg" alt="Discord" />
            Origins: Maze Rats Official Discord - For anything and everything maze related!
          </a>
        </li>
        <hr />
        <li>
          <a href="https://Discord.gg/habbo-origins" target="_blank" rel="noopener noreferrer">
            <img src="/discordLogo.svg" alt="Discord" />
            Habbo Hotel Official Community Discord - Stay updated with the latest Origins Updates or engage in Habbo discussion
          </a>
        </li>
        <hr />
      </ul>
    </div>
  );
}

export default OurFriends;
