import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebaseConfig';

function AnalyticsPage() {
  const [chartData, setChartData] = useState({});
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const q = query(collection(db, 'habbodata'), orderBy('time', 'desc'), limit(30));
      const querySnapshot = await getDocs(q);
      const storedData = querySnapshot.docs.map(doc => doc.data()).reverse();

      const labels = storedData.map(entry => new Date(entry.time).toLocaleString());
      const counts = storedData.map(entry => entry.count);

      setChartData({
        labels,
        datasets: [
          {
            label: 'Habbo Players Over Time',
            data: counts,
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: 'rgba(75,192,192,0.2)',
            pointBackgroundColor: 'rgba(75,192,192,1)',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointRadius: 5,
            pointHoverRadius: 7,
            pointStyle: 'circle',
          },
        ],
      });
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to load data. I probably broke something.');
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 300000); // 300,000 ms = 5 minutes
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="analytics">
      <h1>Habbo Players Over Time</h1>
      {error ? (
        <p>{error}</p>
      ) : (
        <div>
          {chartData.labels ? (
            <div className="chart-container">
              <Line 
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: true,
                      title: {
                        display: true,
                        text: 'Date',
                        color: '#000',
                        font: {
                          size: 14,
                          weight: 'bold',
                        },
                      },
                      ticks: {
                        color: '#000',
                      },
                    },
                    y: {
                      display: true,
                      title: {
                        display: true,
                        text: 'Number of Players',
                        color: '#000',
                        font: {
                          size: 14,
                          weight: 'bold',
                        },
                      },
                      ticks: {
                        color: '#000',
                      },
                      suggestedMin: 0,
                      suggestedMax: 1000,
                    },
                  },
                  plugins: {
                    legend: {
                      display: true,
                      position: 'top',
                      labels: {
                        color: '#000',
                      },
                    },
                  },
                }}
              />
              <p style={{ fontSize: '12px', color: '#000', textAlign: 'center', marginTop: '10px' }}>
                Chart created using <a href="https://react-chartjs-2.js.org/" target="_blank" rel="noopener noreferrer">React Chart.js 2</a> and data from <a href="https://origins.habbo.com/api/public/origins/users" target="_blank" rel="noopener noreferrer">Habbo API</a>. Persistent data saved with Firebase Firestore.
              </p>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      )}
    </div>
  );
}

export default AnalyticsPage;
