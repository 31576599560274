import React, { useState, useEffect } from 'react';
import MazeCard from './MazeCard';
import mazesData from '../data/mazes.json';

const MazeHub = () => {
  const [mazes, setMazes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    difficulty: '',
    type: '',
    theme: ''
  });

  useEffect(() => {
    // loads data from the json file i created mazes.json
    setMazes(mazesData);
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value
    });
  };

  const filteredMazes = mazes.filter(maze => {
    const matchesSearchTerm = maze.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesDifficulty = !filters.difficulty || maze.difficulty === filters.difficulty;
    const matchesType = !filters.type || maze.type.includes(filters.type);
    const matchesTheme = !filters.theme || maze.theme === filters.theme;

    return matchesSearchTerm && matchesDifficulty && matchesType && matchesTheme;
  });

  return (
    <div className="maze-hub">
      <h1>Maze HUB</h1>
      <h2>A hub for all maze creators to easily find, share, and explore various mazes.</h2>
      <input type="text" placeholder="Search..." value={searchTerm} onChange={handleSearch} />
      <select name="difficulty" value={filters.difficulty} onChange={handleFilterChange}>
        <option value="">All Difficulties</option>
        <option value="Easy">Easy</option>
        <option value="Medium">Medium</option>
        <option value="Hard">Hard</option>
      </select>
      <select name="type" value={filters.type} onChange={handleFilterChange}>
        <option value="">All Types</option>
        <option value="Roller">Roller</option>
        <option value="Classical Furni">Classical Furni</option>
        <option value="Illusion">Illusion</option>
        <option value="Beautiful Room">Beautiful Room</option>
        <option value="Tutorial">Tutorial</option>
        <option value="Beginner Friendly">Beginner Friendly</option>
        <option value="Educational">Educational</option>
        <option value="Teleporter">Teleporter</option>
        <option value="Paper">Paper</option>
        <option value="Alternative">Alternative</option>
      </select>
      <div className="maze-cards">
        {filteredMazes.map(maze => (
          <MazeCard key={maze.id} maze={maze} />
        ))}
      </div>
    </div>
  );
};

export default MazeHub;
